<template>
  <ul
    id="main-menu-navigation"
    class="nav navbar-nav"
  >
    <component
      :is="resolveNavComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import PublicNavMenuHeaderLink from '../public-nav-menu-header-link/PublicNavMenuHeaderLink.vue'
import PublicNavMenuHeaderGroup from '../public-nav-menu-header-group/PublicNavMenuHeaderGroup.vue'

export default {
  components: {
    PublicNavMenuHeaderLink,
    PublicNavMenuHeaderGroup
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup () {
    const resolveNavComponent = item => {
      if (item.children) return 'public-nav-menu-header-group'
      return 'public-nav-menu-header-link'
    }

    return {
      resolveNavComponent
    }
  }
}
</script>
