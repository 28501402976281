<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <!--        <b-button-->
        <!--          variant="flat-primary"-->
        <!--          :to="{ name: 'events' }"-->

        <!--          class="ml-1"-->
        <!--        >-->
        <!--          Events-->
        <!--        </b-button>-->

        <!--        <b-button-->
        <!--          variant="flat-primary"-->
        <!--          :to="{ name: 'venues' }"-->
        <!--          class="ml-1"-->
        <!--        >-->
        <!--          Venues-->
        <!--        </b-button>-->
      </li>
    </ul>
  </div>
</template>

<script>
// import { BButton } from 'bootstrap-vue'

export default {
  components: {
    // BButton
  }

}
</script>

<style>

</style>
