<template>
  <div class="navbar-container main-menu-content">
    <public-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/public/index'
import PublicNavMenuItems from './components/public-nav-menu-items/PublicNavMenuItems.vue'

export default {
  components: {
    PublicNavMenuItems
  },
  setup () {
    return {
      navMenuItems
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
