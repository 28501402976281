<template>
  <div style="padding-top: 4px">
    <b-button
      variant="primary"
      :to="{ name: 'auth-login' }"
      class="ml-1"
      size="sm"
    >
      Login
    </b-button>

    <b-button
      variant="info"
      :to="{ name: 'auth-register' }"
      class="ml-1"
      size="sm"
    >
      Sign Up
    </b-button>
  </div>

</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton
  }
}
</script>
