






























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  BLink, BNavbarNav, BImg, BDropdown, BDropdownItem, BDropdownDivider
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import Login from './components/Login.vue'
import { userAbility } from '@/libs/acl/config'
import AppLogo from '@core/layouts/components/AppLogo.vue'
import { Action, Getter } from '@/store/decorators'
import AuthModule from '@/store/modules/authModule'
import { User } from '@/models/User'
import Ripple from 'vue-ripple-directive'
@Component({
  name: 'AppNavbarPublicLayout',
  components: {
    BLink,
    BImg,
    // Navbar Components
    BNavbarNav,

    AppLogo,

    Locale,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    Login,
    BDropdown,
    BDropdownItem,
    BDropdownDivider
  },
  directives: {
    Ripple
  }
})
export default class AppNavbarPublicLayout extends Vue {
  @Action(AuthModule, 'logout') private logoutUser!: () => Promise<void>
  @Getter(AuthModule, 'activeUser') private activeUser!: User

  @Prop() private toggleVerticalMenuActive!: Function;

  private avatarText:string = ''
  private appName:string = $themeConfig.app.appName
  private appLogoImage:string = $themeConfig.app.appLogoImage
}

